// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-authoring-js": () => import("./../../../src/pages/authoring.js" /* webpackChunkName: "component---src-pages-authoring-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-libraries-js": () => import("./../../../src/pages/learning-libraries.js" /* webpackChunkName: "component---src-pages-learning-libraries-js" */),
  "component---src-pages-perform-analytics-platform-js": () => import("./../../../src/pages/perform-analytics-platform.js" /* webpackChunkName: "component---src-pages-perform-analytics-platform-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-library-js": () => import("./../../../src/templates/library.js" /* webpackChunkName: "component---src-templates-library-js" */),
  "component---src-templates-module-js": () => import("./../../../src/templates/module.js" /* webpackChunkName: "component---src-templates-module-js" */)
}

